<template>

  <section id="seguridad">

    <div class="row align-items-center my-3">
      <div class="col-12 col-lg-9">
        <h3 class="section-head-title ps-0 mb-3 mb-lg-0">
          Carga un nuevo documento
        </h3>
      </div>
      <div class="col-12 col-lg-3 d-flex justify-content-end">
        <router-link 
          to="/seguridad/add" 
          class="btn btn-custom btn-custom-color-blue btn-shadow border-round-50">
          <font-awesome-icon icon="plus" class="pe-2"/>
          Cargar documento
        </router-link>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-lg-4 mb-3 mb-lg-0 input-search">
        <input 
          type="search" 
          placeholder="Buscar documento"
          v-model="filter.search"
          class="form-control input-custom"
          @keyup.delete="eraseText()"
        />
        <div class="input-search-icon">
          <font-awesome-icon icon="search"/>
        </div>
      </div>

      <div class="col-12 col-lg-3 mb-3">
        <select v-model="filter.category" class="form-select form-control input-custom">
          <option value="">Todas las categorías</option>
          <option v-for="category in uniqueCategories" :key="category" :value="category">
            {{ category }}
          </option>
        </select>
      </div>

      <div class="col-12 col-lg-3 mb-3">
        <select v-model="filter.format" class="form-select form-control input-custom">
          <option value="">Todos los formatos</option>
          <option v-for="format in uniqueFormats" :key="format" :value="format">
            {{ format }}
          </option>
        </select>
      </div>

    </div>

		<Loading 
			v-if="loading"
			pixeles="150px"
		/>

		<template v-if="!loading">
      <div class="row">
        <div class="col-12 mt-3">
          <div class="table-container">
            <table 
              v-if="document_list.length > 0" 
              class="table table-custom table-borderless table-custom-responsive mb-0">
              <thead>
                <tr>
                  <th scope="col">Nombre</th>
                  <th scope="col" class="text-center">Tipo de archivo</th>
                  <th scope="col" class="text-center">Categoría</th>
                  <th scope="col" class="text-center">Fecha de subida</th>
                  <th scope="col" class="text-center">Acciones</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(doc, k) in document_list" :key="k">
                  <td class="th th-nombre">
                    <font-awesome-icon v-if="doc.id_estado_fk != '1'" :icon="['fas', 'ban']"/>
                    {{ doc.nombre }}
                  </td>
                  <td class="th th-tipo-documento text-center">{{ doc.url_documento.split(".")[doc.url_documento.split(".").length - 1]}}</td>
                  <td class="th th-categoria text-center">{{ doc.categoria[0].nombre }}</td>
                  <td class="th th-fecha-subida text-center">{{ dateFormatymdhours(doc.fecha_creacion) }}</td>
                  <td class="th th-accion text-center">
                    <div 
                      class="dropdown dropdown-custom d-flex justify-content-center" 
                      @click="openOption(doc.id_documento)">
                      <button 
                        class="dropdown-toggle dropdown-custom-action dropdown-custom-arrow-hide" 
                        type="button" 
                        data-bs-toggle="dropdown">
                        <font-awesome-icon icon="ellipsis-vertical"/>
                      </button>
                    <ul 
                      class="dropdown-menu"
                      v-click-outside="outOption">
                      <li>
                        <a 
                          @click="changeDocStatus(doc.id_documento, doc.id_estado_fk)"
                          class="dropdown-item" 
                          href="javascript:">
                          <font-awesome-icon
                            class="pe-2"
                            :class="doc.id_estado_fk  == '1' ? 'color-main' : 'color-secondary'"
                            :icon="doc.id_estado_fk == '1' ? ['fas', 'ban'] : ['fas', 'check']"/>
                          {{ doc.id_estado_fk  == "1" ? "Desactivar" : "Activar" }}
                        </a>
                      </li>
                      <li>
                        <a class="dropdown-item" :href="doc.url_documento" target="_blank">
                          <font-awesome-icon class="pe-2 color-main" icon="eye"/>
                          Vista previa
                        </a>
                      </li>
                      <li>
                        <a 
                          @click="openEditDocument(doc.id_documento)"
                          class="dropdown-item" 
                          href="javascript:">
                          <font-awesome-icon class="pe-2 color-main" icon="pen"/>
                          Editar
                        </a>
                      </li>
                      <li>
                        <a 
                          @click="openQuestionDelete(doc)"
                          class="dropdown-item" 
                          href="javascript:">
                          <font-awesome-icon class="pe-2 color-main" icon="trash-alt"/>
                          Eliminar
                        </a>
                      </li>
                    </ul>
                  </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <SinResultados
              msg="Aceptar" 
              custom-click 
              @click="nuevaBusqueda"  
              v-if="!loading && document_list.length === 0"/>
          </div>
        </div>
      </div>
    </template>

    <Question
      v-if="open_question_modal"
      :msg="question_modal_msg"
      :hideCancel="false"
      @cancel="cancelQuestion"
      @accept="acceptQuestion"
    />

</section>

</template>

<script>
  import Question from "../Modales/Question.vue";
  import { mapActions, mapState } from "vuex";

  //FF
	import Loading from "@/components/Loading.vue";
  import SinResultados from "@/components/SinResultados.vue";

  export default {
    components: { 
        Question, 
        //FF
        Loading,
        SinResultados
    },
    data() {
      return {
        open_question_modal: false,
        question_modal_msg: "",
        id_empresa: this.$ls.get("user").empresa[0].id_empresa,
        document_list: [],
        doc_to_delete: null,
        months: [
          "Enero",
          "Febrero",
          "Marzo",
          "Abril",
          "Mayo",
          "Junio",
          "Julio",
          "Agosto",
          "Septiembre",
          "Octubre",
          "Noviembre",
          "Diciembre",
        ],
        option: "",

        //FF
        loading: '',
        filter: { 
          search: '',
          category: "",
          format: ""
        },
      };
    },
    mounted() {
      this.getDocuments();
    },
    computed: {
      ...mapState("docsModule", ["safety_docs_list"]),
      count() {
        return this.$store.state.docsModule.safety_docs_list.length;
      },

      uniqueCategories() {
        const categories = new Set(this.safety_docs_list.map(doc => doc.categoria[0].nombre));
        return Array.from(categories);
      },
      uniqueFormats() {
        const formats = new Set(this.safety_docs_list.map(doc => doc.url_documento.split(".").pop()));
        return Array.from(formats);
      },
    },
    watch: {
    'filter.search'() {
      this.filterDocuments();
    },
    'filter.category'() {
      this.filterDocuments();
    },
    'filter.format'() {
      this.filterDocuments();
    },
    count() {
      this.loading = false;
      this.document_list = this.safety_docs_list;
      this.filterDocuments();
    },
    },
    methods: {
      ...mapActions("docsModule", ["getDocsList", "deleteDocAction", "editDocAction", "cambiaEstadoDoc"]),

      nuevaBusqueda(){
        this.filter.search = '';
        this.filter.category = '';
        this.filter.format = '';
        this.$refs.searchInput.focus();
        this.getDocuments();
      },
      filterDocuments() {
        let filtered = this.safety_docs_list;

        if (this.filter.search) {
          const searchLower = this.filter.search.toLowerCase();
          filtered = filtered.filter(doc => doc.nombre.toLowerCase().includes(searchLower));
        }

        if (this.filter.category) {
          filtered = filtered.filter(doc => doc.categoria[0].nombre === this.filter.category);
        }

        if (this.filter.format) {
          filtered = filtered.filter(doc => doc.url_documento.split(".").pop() === this.filter.format);
        }

        this.document_list = filtered;
      },

      openQuestionDelete(doc) {
        this.doc_to_delete = doc;
        this.open_question_modal = true;
        this.question_modal_msg = "¿Estás seguro de eliminar este protocolo?";
      },
      cancelQuestion() {
        this.open_question_modal = false;
      },

      // eliminar documento
      async acceptQuestion() {
        this.loading = true;
        let data_ = {
          id_documento: this.doc_to_delete.id_documento,
          id_empresa: this.id_empresa,
          id_eliminador: this.$ls.get("user").id_usuario,
        }
        await this.deleteDocAction(data_).then(res=>{
          if(res){
            this.loading = false;
            this.$toast.open({
              message: "Se eliminó correctamente el documento en la sección de seguridad.",
              type: "success",
              duration: 5000,
              position: "top-right",
            });
            this.open_question_modal = false;
          }
          else{
            this.loading = false;
            this.$toast.open({
              message: 'Ocurrió un error al eliminar el documento, intentalo nuevamente',
              type: 'error',
              duration: 5000,
              position: 'top-right'
            });
            this.open_question_modal = false;
          }
        }).catch((err)=>{
          console.log("Error: ", err);
          this.loading = false;
          this.$toast.open({
            message: 'Ocurrió un error al eliminar el documento, favor contactarse con el administrador',
            type: 'error',
            duration: 5000,
            position: 'top-right'
          });
          this.open_question_modal = false;
        });
      },
      openEditDocument(idDoc) {
        this.$router
          .push({
            name: "seguridad-edit",
            params: { id: idDoc },
          })
          .catch(() => {
            return;
          });
      },
      async getDocuments(text) {
        if(text == undefined) text = '';
        this.loading = true;
        this.document_list = [];
        var variables_search = {
          id_empresa: this.id_empresa,
          id_categoria: "0", //Categoría: "Todas"
          id_modulo: "9",
          plataforma: "2", //Web
          nombre: text,
        };
        await this.getDocsList(variables_search);
        this.document_list = this.safety_docs_list;
        this.loading = false;
      },
      //En proceso para deprecar
      async changeDocStatus(id_documento, id_estado_fk) {
        this.loading = true;
        let status = id_estado_fk == "1"? "3" : "1";
        let data_ = {
          id_documento: id_documento,
          id_empresa: this.id_empresa,
          id_modificador: this.$ls.get("user").id_usuario,
          estado: status,
        }
        await this.cambiaEstadoDoc(data_).then(res=>{
          if(res){
            this.loading = false;
            this.$toast.open({
              message: "Se deshabilito correctamente el documento, este documento ya no estará visible para los colaboradores",
              type: "success",
              duration: 5000,
              position: "top-right",
            });
            this.open_question_modal = false;
          }
          else{
            this.loading = false;
            this.$toast.open({
              message: 'Ocurrió un error al intantar deshabilitar el documento, intentalo nuevamente',
              type: 'error',
              duration: 5000,
              position: 'top-right'
            });
            this.open_question_modal = false;
          }
        }).catch((err)=>{
          console.log("Error changeDocStatus, error: ", err);
          this.$toast.open({
              message: 'Ocurrió un error al intantar deshabilitar el documento, favor contactate con el administrador',
              type: 'error',
              duration: 5000,
              position: 'top-right'
          });
            this.open_question_modal = false;
        });
      },
      eraseText() {
        if (this.filter.search.length == 0) {
          this.document_list = this.safety_docs_list;
        }
      },


      //abrir opciones
      openOption(id) {
        setTimeout(() => {
          this.option = id;
        }, 10);
      },
      outOption() {
        if (this.option != "") {
          this.option = "";
        }
      },
    },
  };
</script>
